import React, { useEffect, useRef, useState } from 'react'
import { getExamResultForAdmin,editResult,updateResult } from '../../../apis/ExamApi';
import { toastError, toastSuccess } from '../../../global/Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { env } from 'process';

const Results = () => {

  let {type} = useParams()
const editButton = useRef(null);
 const [file, setFile] = useState('')

 const [editdata, setEditData] = useState({
  id:"",
  exam_result_status: '',
  exam_result_marks: '',
  exam_result_file: '',
})
  const [search, setSearch] = useState('')
const [isLoading,setIsLoading]=useState();
const [resultData,setResultData]=useState([]);

const [page, setPage] = useState(1)
const [totalPage, setTotalPage] = useState(0)
const [perPage, setPerPage] = useState(5)

const [stdId, setStdId] = useState('')
const imageUrl = process.env.REACT_APP_IMAGE_URL

const getAllResultFunc = async (pageNo) => {
   
  setIsLoading(true)

  let data = JSON.stringify({
    std_id: type=='admin'?0:window.localStorage.getItem('stdId'),
    type:1

  })

  await getExamResultForAdmin(data,pageNo)
    .then((response) => {
      setIsLoading(false)
  

      if (response.data.data.data.length > 0) {
        setPerPage(response.data.data.per_page)
        setTotalPage(response.data.data.total)
        setResultData(response.data.data.data)
      } 
      else {
        toastError('Content not available')
        setResultData('')
      }

    })
    .catch((err) => {
      console.log(err)
      setIsLoading(false)
    })
}

const handleGetAllCourses = (e) => {
  const name = e.target.name
  const value = e.target.value
  // console.log(name, value)
  setEditData((prev) => {
    return {
      ...prev,
      [name]: value,
    }
  })
}

const autoButtonClick = (buttonType) => {
  buttonType.current.addEventListener('click', handleGetAllCourses)
  buttonType.current.click()
}

const handleChange = (e) => {
  const name = e.target.name
  const value = e.target.value

  setEditData((prev) => {
    return {
      ...prev,
      [name]: value,
    }
  })

}

const handleEdit = async (id) => {
    let dataID = JSON.stringify({
      id: id,
    })

    await editResult(dataID)
      .then((response) => {
        console.log(response.data.data);
     setEditData({
      id:response.data.data.id,
      exam_result_status: (response.data.data.status == "Pass"?1:0),
      exam_result_marks: response.data.data.correct_ans,
      exam_result_file: response.data.data.media_file,
    })
      })
      .catch((err) => {
        console.log(err)
      })
  }

 const handleUpdateResult = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const FormData = require("form-data");
    let newData = new FormData();
    newData.append("id", editdata.id);
    newData.append("exam_result_status", editdata.exam_result_status);
    newData.append("exam_result_marks", editdata.exam_result_marks);
    newData.append("exam_result_file",file);
    
    await updateResult(newData)
      .then((response) => {
        setIsLoading(false)
        //  console.log('update Response',response )

        if (response.data.code == 200) {
          autoButtonClick(editButton)
       
         getAllResultFunc();
          toastSuccess('Record updated successfully')
        }

        if (response.data.error) {
          // toastError(response.data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }




  const modalCloseEvent=()=>{
    setEditData({
      id:'',
    exam_result_status: '',
    exam_result_marks: '',
    exam_result_file: '',
    });
  }




const navigate=useNavigate();

useEffect(() => {
  setStdId(window.localStorage.getItem('stdId'))
  getAllResultFunc(page)
}, [])
  return (
    <>
        <h2>Result List</h2>
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder='Search Student'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>


            </div>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>STUDENT NAME</th>
                    <th className='text-end'>STUDENT ID</th>
                    <th className='text-end'>HELD ON</th>
                    <th className='text-end'>COURSE</th>
                    <th className='text-end'>EXAM TYPE</th>
                    <th className='text-end'>STATUS</th>
                    <th className='text-end'>ACTIONS</th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
               
                     
                {resultData
                    ? resultData.filter((item) => {
                      return search.toLowerCase() === ''
                        ? item
                        : item.student.name.toLowerCase().includes(search) ||
                            item.student.tarffic_id.includes(search)
                    }).map((item) => {
                   
                          return (

                            <tr key={item.id}>


                            <td className='d-flex align-items-center'>
                                <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                  <a>
                                    <div className='symbol  symbol-40px symbol-circle'>
                                      
                          {
                            item.image?

                            <img src={imageUrl + item.student.image} />
          :     <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
           }
                                    </div>
                                  </a>
                                </div>

                                <div className='d-flex flex-column'>
                                <a>{item.student ? item.student.name + ' ' + (item.student.l_name?item.student.l_name:'') : '-'}</a>
                               
                                <span>{item.student && item.student.email !== null ? item.student.email:''}</span>
                                </div>
                              </td>

                            <td className='text-end'>{item.student?item.student.tarffic_id:''}</td>
                            <td className='text-end'>{item.created_at}</td>
                            <td className='text-end'>{item.course?item.course.cat_title:''}</td>
                            <td className='text-end'>
                            {item && item.exam && item.exam.exam_type == 1 ?
                            <span className='badge badge-light-primary'>Manual</span>
                            :
                            <span className='badge badge-light-primary'>Default</span>
                            }
                            </td>
                            <td className='text-end'>
                            {item && item.correct_ans >= item.correct_ans_required?
                            <span className='badge badge-light-success'>Pass</span>
                            :
                            <span className='badge badge-light-danger'>Fail</span>
                            }
                            </td>
                            <td className='text-end'>
                            {item.student ?
                            <Dropdown>
                                  <Dropdown.Toggle variant='light'>Actions</Dropdown.Toggle>
                             
                                  <Dropdown.Menu>
                                  {item.exam && item.exam.exam_type !== null && item.exam.exam_type !== 0 ? (
                                        <>
                                          <Dropdown.Item
                                               data-bs-toggle='modal'
                                               data-bs-target='#edit_exam'
                                               onClick={() => {
                                                  handleEdit(item.id)
                                               }}
                                                  >
                                                 {' '}
                                                 Edit
                                          </Dropdown.Item>
                                          {item.media_file !== null && item.media_file !== ""?
                                          <Dropdown.Item
                                           
                                            as="a"
                                            href={process.env.REACT_APP_IMAGE_URL+'/'+item.media_file}
                                            download
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Download
                                          </Dropdown.Item>
                                           :""}
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(`/print-result/${item.exam_id}`);
                                            }}
                                          >
                                            Print
                                          </Dropdown.Item>
                                        </>
                                      ) : (
                                        <>
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(`/view-result/${item.exam_id}/${item.student.name}`);
                                            }}
                                          >
                                            View
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(`/print-result/${item.exam_id}`);
                                            }}
                                          >
                                            Print
                                          </Dropdown.Item>
                                        </>
                                      )}
                                    </Dropdown.Menu>

                             
                                </Dropdown>
                                   :''}
                             
                             
                            </td>

                            </tr>

                            )
                        })
                    : ''}
                 
                </tbody>
              </table>
              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
                    getAllResultFunc(page)
                  }}
                  ellipsis={1}
                />
              </div>
            
            </div>
          </div>
        </div>
      </div>
        {/* edit exam modal */}

        <div className='modal fade' id='edit_exam' tabindex='-1' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-750px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Edit Result</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-roles-modal-action='close'
                data-bs-dismiss='modal'
                onClick={()=> modalCloseEvent()}
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='black'
                    />
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='black'
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className='modal-body scroll-y mx-lg-5 my-7'>
          
              <form onSubmit={handleUpdateResult}>
                  <input type='hidden' name='id' value={editdata.id}></input>
              <div className='fv-row mb-10'>
                  <div className='row'>
                 
                  <div className='col-md-12'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Exam Result Status</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Course Short Title'
                        ></i>
                      </label>

                      <select
                      name='exam_result_status'
                      className='form-control'
                      value={editdata.exam_result_status}
                      onChange={handleChange}
                      required
                    >
                      <option value=''>Choose Exam Result Status</option>
                      {[
                         { id: '0', name: 'Fail' },
                        { id: '1', name: 'Pass' },
                      ].map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    </div>

                  </div>
                </div>
              <div className='fv-row mb-10'>
                  <div className='row'>
                 
                    <div className='col-md-6'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Total Correct Answers</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Total Correct Answers'
                        ></i>
                      </label>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='exam_result_marks'
                        required
                        
                        onChange={handleChange}
                        value={editdata.exam_result_marks}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Upload Exam Result</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Exam Result Status'
                        ></i>
                      </label>

                      <input
                        type='file'
                        className='form-control form-control-lg form-control-solid'
                        name='exam_result_file'
                      
                        onChange={(e) => {
                          setFile(e.target.files[0])
                        }}
                  
                      />
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-center flex-row-fluid pt-12'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-bs-dismiss='modal'
                    id='modalCancel'
                    ref={editButton}
                    onClick={()=> modalCloseEvent()}
                  >
                    Cancel
                  </button>

                  <button type='submit' className='btn btn-primary'>
                    {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Update'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Results

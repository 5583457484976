import React, {useEffect, useRef, useState} from 'react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Dropdown} from 'react-bootstrap'
import {
  deleteCategory,
  editCategory,
  getAllCategory,
  getAllCourses,
  getCourseForDropdown,
  saveCategory,
  updateCategory,
} from '../apis/CategoryApi'
import {Link} from 'react-router-dom'
import {swalSuccess, toastError, toastSuccess} from '../global/Global'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import {getAllStudents, getStudentsForDropdown} from '../apis/StudentApi.js'
import {getInvigilatorForDropdown} from '../apis/UserApi'
import {exit} from 'process'
import {createExam, deleteExam, editExam, getAllExamList, updateExam} from '../apis/ExamApi'
import { updateEmail } from '../../app/modules/accounts/components/settings/SettingsModel'
import Select from "react-select";

const Exams = () => {
  const saveButton = useRef(null)
  const editButton = useRef(null)

  const [examList, setExamList] = useState([])
  const [examType, setExamType] = useState('0');
  const [file, setFile] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [courseList, setCourseList] = useState([])
  const [stdList, setStdList] = useState([])
  const [invgList, setInvgList] = useState([])

  const [allStdData, setAllStdData] = useState([])

  const [title, setTitle] = useState('')
  const [code, setCode] = useState('')

  const [status, setStatus] = useState(true)
  const [id, setId] = useState('')
  const [stdData, setStdData] = useState([])

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
 
  const [isStatusCheck, setIsStatusCheck] = useState(false)
  const imageUrl = process.env.REACT_APP_IMAGE_URL
  const [stdId, setStdId] = useState('')

  const [data, setData] = useState({
    course_id: '',
    invg_id: '',
    exam_date: '',
    exam_type: '',
    exam_result_status: '',
    exam_result_marks: '',
    exam_result_file: '',
  })

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
  
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
    if (name === 'exam_type') {
      setExamType(value);
    }
  }

  const handleGetAllCourses = (e) => {
    const name = e.target.name
    const value = e.target.value
    // console.log(name, value)
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const autoButtonClick = (buttonType) => {
    buttonType.current.addEventListener('click', handleGetAllCourses)
    buttonType.current.click()
  }

  const handleGetExamList = async (pageNo) => {

    setIsLoading(true)

    await getAllExamList(pageNo)
      .then((response) => {
   
        if (response.data.code != 206) {
          setPerPage(response.data.data.per_page)
          setTotalPage(response.data.data.total)
          setExamList(response.data.data.data)
        } else {
          setExamList('')
          toastError('Content not available')
        }
        setIsLoading(false)
      })
      .catch((err) => {
    
        setIsLoading(false)
      })
  }

  const getAllStudentsForDropdown = async () => {
    setIsLoading(true)

    await getStudentsForDropdown()
      .then((response) => {

      
        if (response.data.code == 200) {
          setStdList(response.data.data)
        } else {
          setStdList('')
          toastError('Student List Empty')
        }
        setIsLoading(false)
      })
      .catch((err) => {
     
        setIsLoading(false)
      })
  }

  const getAllCourseForDropdown = async () => {
    setIsLoading(true)

    await getCourseForDropdown()
      .then((response) => {
        // console.log('getCourseForDropdown', response.data.code)
        if (response.data.code != 206) {
          setCourseList(response.data.data)
        } else {
          setStdList('')
          toastError('Content not available')
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const getAllInvigilatorForDropdown = async () => {
    setIsLoading(true)

    await getInvigilatorForDropdown()
      .then((response) => {
        if (response.data.code != 206) {
          setInvgList(response.data.data)
        } else {
          setInvgList('')
          toastError('Content not available')
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }




  const handleStatusChange = () => {
    setStatus(!status);
  };

  const deleteRecord = async (id) => {
    try {
      let data = JSON.stringify({
        id: id,
      })

      await deleteExam(data)
        .then((response) => {
          handleGetExamList(page)
          toastSuccess('Record deleted successfully')
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      toastError('Record not deleted')
    }
  }

  const handleDelete = (id) => {
    Swal.fire({
      text: 'Are you sure you want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(id)
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    
    const FormData = require("form-data");
    let newData = new FormData();
    newData.append("std_id",stdId);
    newData.append("course_id", data.course_id);
    newData.append("invg_id", data.invg_id);
    newData.append("exam_date", data.exam_date);
    newData.append("exam_type", data.exam_type);
    newData.append("exam_result_status", data.exam_result_status);
    newData.append("exam_result_marks", data.exam_result_marks);
    newData.append("exam_result_file",file);
    newData.append("status",(status)?0:1);
    

    await createExam(newData)
      .then((response) => {
        setIsLoading(false)
   
        // console.log('Post Responce', response.data.code)

        if (response.data.code == 200) {
          autoButtonClick(saveButton)
          setTitle('')
          setStatus(false)
          setCode('')
          handleGetExamList(page)

          toastSuccess('Record save successfully')
        }
        if (response.data.code==404) {
          toastError(response.data.message)
          
        }

        if (response.data.error) {
          toastError(response.data.error)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const handleEdit = async (id) => {
    let data = JSON.stringify({
      id: id,
    })

    await editExam(data)
      .then((response) => {
    
        var statValue = false;
        if (response.data.data.status == "Active") {
          statValue = true;
        }

        setStatus(statValue);
        
        setId(response.data.data.id);
        setStdId(response.data.data.std_id);
     setData({
     
      course_id: response.data.data.course_id,
      invg_id: response.data.data.invg_id,
      exam_date: response.data.data.exam_date,
     
     })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleUpdateExam = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    let updateData = JSON.stringify({
   
      id:id,
      std_id:stdId,
      course_id: data.course_id,
      invg_id: data.invg_id,
      exam_date: data.exam_date,
      status:(status)?0:1,
    })

    await updateExam(updateData)
      .then((response) => {
        setIsLoading(false)
        //  console.log('update Response',response )

        if (response.data.code == 200) {
          autoButtonClick(editButton)
       
          handleGetExamList()
          toastSuccess('Record updated successfully')
        }

        if (response.data.error) {
          // toastError(response.data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const modalCloseEvent=()=>{
  
    setData({
      std_id: '',
      course_id: '',
      invg_id: '',
      exam_date: '',
    });
    // setStatus(false);
    setId('');
  }


  const handleStdSelect=(data)=>{
    setStdId(data.value);
  }
  
  

  useEffect(() => {
    getAllStudentsForDropdown()
    getAllCourseForDropdown()
    getAllInvigilatorForDropdown()
    handleGetExamList(page)

  }, [])

  const [permArray, setPermArray] = useState([])
  useEffect(() => {
    const storedArray = window.localStorage.getItem('permissions')
    if (storedArray) {
      setPermArray(JSON.parse(storedArray))
    }
  }, [])


  var stdOptionList = [];
  stdList && stdList.map((item, i) =>
  stdOptionList.push({
      value: item.id,
      label: item.name + ' '+ item.tarffic_id
    }),
  );

  return (
    <>
      <h2>Exam List</h2>

      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder='Search Exam'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              <div className='card'>
              {permArray.some(item => item.name === 'exam-create')? 
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                  <button
                    className='btn btn-sm btn-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_app'
                    style={{marginRight: '-30px'}}
                
                  >
                    Add New Exam
                  </button>
                </div>
                :''}
              </div>
            </div>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>

                
                    <th className='min-w-125px'>NAME/EMAIL</th>
                    <th >TRAFFIC ID</th>
                    <th>TEST GENERATED BY</th>
                    <th>EXAM TYPE</th>
                    <th>TEST HELD</th>
                    <th>TEST STATUS</th>
                    <th>TEST DATE</th>
                    <th className='text-end'>ACTIONS</th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
             
           
                  {examList
                    ? examList
                        .filter((item) => {
                          return search.toLowerCase() === ''
                            ? item
                            : item.student.name.toLowerCase().includes(search) ||
                            item.student.tarffic_id.includes(search)
                          
                        })
                        .map((item) => {
                          return (
                            <tr key={item.id}>

                      

                              <td className='d-flex align-items-center'>
                                <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                  <a>
                                    <div className='symbol  symbol-40px symbol-circle'>
                                      
                          {
                            item.image?

                            <img src={imageUrl + item.student.image} />
          :     <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
           }
                                    </div>
                                  </a>
                                </div>

                                <div className='d-flex flex-column'>
                                
                         
                                  <a>{item.student ? item.student.name + ' ' + item.student.l_name : '-'}</a>


                                  <span>{item.student && item.student.email!='null'? item.student.email:''}</span>
                                </div>
                              </td>

                              <td>{item.student?item.student.tarffic_id:'-'}</td>

                              <td>
                                <div className='badge badge-light-success'>{item.invigilator && item.invigilator.name}</div>
                              </td>
                              <td>
                              <span className={`badge badge-light-${item.exam_type==1 ? 'primary' : 'primary'}`}>{item.exam_type==1?'Manual':'Default'}</span>
                              </td>
                              <td>
                              <span className={`badge badge-light-${item.is_attempt==1 ? 'success' : 'danger'}`}>{item.is_attempt==1?'Held':'Pending'}</span>
                              
                              </td>
                        
                              <td>
                              <span className={`badge badge-light-${item.status=='Active' ? 'success' : 'danger'}`}>{item.status}</span>
                               
                              </td>

                              <td>{item.exam_date}</td>

                              <td className='text-end'>
                              {item.student ?
                            <Dropdown>
                                  <Dropdown.Toggle variant='light'>Actions</Dropdown.Toggle>

                                  <Dropdown.Menu>
                                  {permArray.some(item => item.name === 'exam-edit')? 
                                    <Dropdown.Item
                                      data-bs-toggle='modal'
                                      data-bs-target='#edit_exam'
                                      onClick={() => {
                                        handleEdit(item.id)
                                      }}
                                    >
                                      {' '}
                                      Edit
                                    </Dropdown.Item>
                                    :''}
                                    {permArray.some(item => item.name === 'exam-delete')? 
                                    <Dropdown.Item
                                      onClick={() => {
                                        handleDelete(item.id)
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>

                                    :''}
                                  
                                  </Dropdown.Menu>
                                </Dropdown>
                                :''}
                              </td>
                            </tr>
                          )
                        })
                    : ''}
                </tbody>
              </table>

              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
                    handleGetExamList(page)
                  }}
                  ellipsis={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* add exam modal */}

      <div className="modal fade" id="kt_modal_create_app" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div className='modal-dialog modal-dialog-centered mw-750px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Schedule New Exam</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-roles-modal-action='close'
                data-bs-dismiss='modal'
                onClick={()=> modalCloseEvent()}
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='black'
                    />
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='black'
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className='modal-body scroll-y mx-lg-5 my-7'>
              <form onSubmit={handleSubmit} >
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Student</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Course Name'
                    ></i>
                  </label>

 


           <Select  placeholder="Choose Student"
        
          options={stdOptionList}
      
          onChange={handleStdSelect}
          isSearchable={true}
          required={true}
         
        />  
                  <div className='text-danger'></div>
                </div>

              

                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Course</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Course Short Title'
                    ></i>
                  </label>

                  <select
                    name='course_id'
                    className='form-control'
                    value={data.course_id}
                    onChange={handleChange}
                    required
                  >
                    <option value=''>Choose Course</option>
                    {courseList
                      ? courseList.map((item) => {
                          return <option value={item.id}>{item.cat_title}</option>
                        })
                      : ''}
                  </select>
                  <div className='text-danger'></div>
                </div>

                <div className='fv-row mb-10'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Invigilator</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Course Short Title'
                        ></i>
                      </label>

                      <select
                        name='invg_id'
                        className='form-control'
                        value={data.invg_id}
                        onChange={handleChange}
                        required
                        
                      >
                        <option value=''>Choose Invigilator</option>
                        {invgList
                          ? invgList.map((item) => {
                              return <option value={item.id}>{item.name} {item.l_name} ({item.user_id})</option>
                            })
                          : ''}
                      </select>
                    </div>

                    <div className='col-md-6'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Exam Date</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Course Short Title'
                        ></i>
                      </label>

                      <input
                        type='date'
                        className='form-control form-control-lg form-control-solid'
                        name='exam_date'
                        required
                        
                        onChange={handleChange}
                        value={data.exam_date}
                      />
                    </div>
                  </div>
                </div>
                <div className='fv-row mb-10'>
                  <div className='row'>

                  <div className='col-md-6'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className=''>Exam Type</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Choose Exam Type'
                      ></i>
                    </label>

                    <select
                          name='exam_type'
                          className='form-control'
                          value={data.exam_type || '0'}
                          onChange={handleChange}
                          required
                        >
                          {[
                            { id: '0', name: 'Default Exam' },
                            { id: '1', name: 'Manual Exam' },
                          ].map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                  </div>
                  {examType === '1' && (
        <>
                   <div className='col-md-6'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Exam Result Status</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Course Short Title'
                        ></i>
                      </label>

                      <select
                      name='exam_result_status'
                      className='form-control'
                      value={data.exam_result_status}
                      onChange={handleChange}
                      required
                    >
                      <option value=''>Choose Exam Result Status</option>
                      {[
                         { id: '0', name: 'Fail' },
                        { id: '1', name: 'Pass' },
                      ].map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    </div>
                  </>)}
                  </div>
                </div>
                {examType === '1' && (
        <>
                <div className='fv-row mb-10'>
                  <div className='row'>
                 
                    <div className='col-md-6'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Total Correct Answers</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Total Correct Answers'
                        ></i>
                      </label>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='exam_result_marks'
                        required
                        
                        onChange={handleChange}
                        value={data.exam_result_marks}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Upload Exam Result</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Exam Result Status'
                        ></i>
                      </label>

                      <input
                        type='file'
                        className='form-control form-control-lg form-control-solid'
                        name='exam_result_file'
                        required
                        onChange={(e) => {
                          setFile(e.target.files[0])
                        }}
                  
                      />
                    </div>
                  </div>
                </div>
                
                </>)}
              

                <div className='d-flex flex-stack'>
                  <div className='me-5 fw-bold'>
                    <label className='fs-6'>Exam Status</label>
                  </div>

                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={status}
                      onChange={(e) => {
                        handleStatusChange()
                      }}
                    />

                    <span className='form-check-label fw-bold text-muted'>Active</span>
                  </label>
                </div>

                <div className='d-flex flex-center flex-row-fluid pt-12'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-bs-dismiss='modal'
                    id='modalCancel'
                    ref={saveButton}
                    onClick={()=> modalCloseEvent()}
                  >
                    Cancel
                  </button>

                  <button type='submit' className='btn btn-primary'>
                    {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

          {/* edit exam modal */}

          <div className='modal fade' id='edit_exam' tabindex='-1' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-750px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Edit  Exam</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-roles-modal-action='close'
                data-bs-dismiss='modal'
                onClick={()=> modalCloseEvent()}
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='black'
                    />
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='black'
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className='modal-body scroll-y mx-lg-5 my-7'>
          
              <form onSubmit={handleUpdateExam}>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Student</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Course Name'
                    ></i>
                  </label>
                  <select
                    name='std_id'
                    className='form-control'
                    value={stdId}
                    onChange={handleChange}
                    required
                  >
                    <option value=''>Choose Student</option>
                    {stdList
                      ? stdList.map((item) => {
                          return <option value={item.id}>{item.name}</option>
                        })
                      : ''}
                  </select>

                  
            <div className='text-danger'></div>
                </div>

                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Course</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Course Short Title'
                    ></i>
                  </label>

                  <select
                    name='course_id'
                    className='form-control'
                    value={data.course_id}
                    onChange={handleChange}
                    required
                  >
                    <option value=''>Choose Course</option>
                    {courseList
                      ? courseList.map((item) => {
                          return <option value={item.id}>{item.cat_title}</option>
                        })
                      : ''}
                  </select>
                  <div className='text-danger'></div>
                </div>

                <div className='fv-row mb-10'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Invigilator</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Course Short Title'
                        ></i>
                      </label>

                      <select
                        name='invg_id'
                        className='form-control'
                        value={data.invg_id}
                        onChange={handleChange}
                        required
                        
                      >
                        <option value=''>Choose Invigilator</option>
                        {invgList
                          ? invgList.map((item) => {
                              return <option value={item.id}>{item.name}</option>
                            })
                          : ''}
                      </select>
                    </div>

                    <div className='col-md-6'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className=''>Exam Date</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Course Short Title'
                        ></i>
                      </label>

                      <input
                        type='date'
                        className='form-control form-control-lg form-control-solid'
                        name='exam_date'
                        
                        onChange={handleChange}
                        value={data.exam_date}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-5 fw-bold'>
                    <label className='fs-6'>Exam Status </label>
                  </div>

                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      onChange={(e) => handleStatusChange()}
                      checked={status}

                    />

                    <span className='form-check-label fw-bold text-muted'>Active</span>
                  </label>
                </div>

                <div className='d-flex flex-center flex-row-fluid pt-12'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-bs-dismiss='modal'
                    id='modalCancel'
                    ref={editButton}
                    onClick={()=> modalCloseEvent()}
                  >
                    Cancel
                  </button>

                  <button type='submit' className='btn btn-primary'>
                    {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Exams

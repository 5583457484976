import React, {useEffect, useState} from 'react'
import {getExamResult, printResult} from '../../../apis/ExamApi'
import {Link, useParams} from 'react-router-dom'
import {toastError} from '../../../global/Global'
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { useTranslation } from 'react-i18next';

const StdResults = () => {

   
  const { t, i18n } = useTranslation();

   let {examId} = useParams()

  const [examDate, setExamDate] = useState('')
  const [stdName, setStdName] = useState('')
  const [trafficId, setTrafficId] = useState('')
  const [isShowBoth, setIsShowBoth] = useState(false)

  const [examTime, setExamTime] = useState('')
  const [courseName, setCourseName] = useState('')

  const [totalQuestion, setTotalQuestion] = useState('')
  const [requireAns, setRequireAns] = useState('')
  const [correctAns, setCorrectAns] = useState('')
  const [examDuration, setExamDuration] = useState('')
  const [direction, setDirection] = useState('ltr')
  const [currentLocale, setCurrentLocale] = useState('en')

  const [resPageLogo, setResPageLogo] = useState('')

  const imageUrl = process.env.REACT_APP_IMAGE_URL

  const handlePrintResult = async (id) => {
    let data = JSON.stringify({
      examId:examId,
    })

    await printResult(data)
      .then((response) => {

      // console.log('response',response);

        if (response.data.code != 206) {

          setCourseName(response.data.data.examResult.course.cat_title)
          setExamTime(response.data.data.examResult.total_duration)
          setExamDuration(response.data.data.examResult.test_duration)

          setStdName(response.data.data.examResult.student.name)
          setTrafficId(response.data.data.examResult.student.tarffic_id)
          setExamDate(response.data.data.examResult.created_at)
          setTotalQuestion(response.data.data.examResult.total_question)
          setRequireAns(response.data.data.examResult.correct_ans_required)
          setCorrectAns(response.data.data.examResult.correct_ans)
        } else {
          toastError('Result not found')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handlePrintPreview = () => {
   window.print(); // Open the print dialog
 };


 const getAppLogoFromLocalStorage = async () => {

 setResPageLogo(localStorage.getItem('resPageLogo'));
 };

 useEffect(() => {
   getAppLogoFromLocalStorage();
 }, []);


 //convertToArabic
 const convertToArabic = (isBothFalse) => {
   
      setDirection('rtl');
      i18n.changeLanguage('ae');
      setCurrentLocale('ae');
      if(isBothFalse==1){
         setIsShowBoth(false);
      }
 
 };
 
 const convertToEnglish = (isBothFalse) => {
   
   setDirection('ltr');
   i18n.changeLanguage('en');
   setCurrentLocale('en');
   if(isBothFalse==1){
      setIsShowBoth(false);
   }

};


const handleBothState = () => {
   setIsShowBoth(true);
   convertToArabic(0);
 
};


  useEffect(() => {
   i18n.changeLanguage('en');
   handlePrintResult()
  }, [])


  const printToPDF = () => {
       
   const doc = new jsPDF();
   const pdfTable = document.getElementById('divToPdf');
   var html = htmlToPdfmake(pdfTable.innerHTML);
   const documentDefinition = { content: html };
   pdfMake.vfs = pdfFonts.pdfMake.vfs;
   pdfMake.createPdf(documentDefinition).open();

};

  return (
    <>
    
    <div class="d-flex flex-column flex-root app-root" id=""  dir={direction}>
   <div class="app-page  flex-column flex-column-fluid " id="kt_app_page">
      <div id="kt_app_header" class="app-header " data-kt-sticky="true" data-kt-sticky-activate="{default: true, lg: true}" data-kt-sticky-name="app-header-minimize" data-kt-sticky-offset="{default: '200px', lg: '0'}" data-kt-sticky-animation="false">
         <div class="app-container  container-xxl flex-center" id="kt_app_header_container">
            <div class="d-flex flex-center mb-5">
               <a href="#">
           {resPageLogo && resPageLogo!='undefined'?
               <img alt={resPageLogo} src={imageUrl + resPageLogo} class="h-100px h-lg-100px app-sidebar-logo-default theme-light-show"/>
               :''}
               </a>
            </div>
         </div>
      </div>
      <div class="app-wrapper  flex-column flex-row-fluid " id="divToPdf">
         <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div class="d-flex flex-column flex-column-fluid">
               <div id="kt_app_content" class="app-content  flex-column-fluid ">
                  <div id="kt_app_content_container" class="app-container  container-xxl">
                     <div class="card mb-10">
                        <div class="table-responsive">
                           <table class="table table-rounded table-striped border gy-7 gs-7 mb-0">

                              <tbody class="heabody">

                                 <tr>
                                    <th style={{width:"300px;"}}> {isShowBoth?'Test Date /':''}  {t('Test Date')}</th>
                                    {currentLocale=='ae'?
                                    <td style={{direction:"ltr",textAlign:"end"}}> {examDate}</td>
                                    :
                                    <td> {examDate}</td>
                                     }
                                         
                                 </tr>
                                 <tr>
                                    <th>{isShowBoth?'Student Name /':''}  {t('Student_Name')}</th>
                                    <td>{stdName}</td>
                                 </tr>
                                 <tr>
                                    <th>{isShowBoth?'Traffic File Number /':''} {t('Traffic File Number')}</th>
                                    <td>{trafficId}</td>
                                 </tr>
                                 <tr>
                                    <th>{isShowBoth?'Course /':''} {t('Course')}</th>
                                    <td>{courseName}</td>
                                 </tr>
                                 <tr>
                                    <td>{isShowBoth?'Result Status /':''}  {t('Result Status')}</td>
                                    <td>{correctAns >= requireAns ? 'Passed': 'Failed' }</td>
                                 </tr>
                                 <tr>
                                    <td>{isShowBoth?'Total duration (In minute)/':''} {t('Total duration (In minute)')}</td>
                                    <td>{examTime}</td>
                                 </tr>
                                 <tr>
                                    <td>{isShowBoth?'Test Duration (In minutes))/':''} {t('Test Duration (In minutes)')}</td>
                                    <td>{examDuration}</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>

                     <div class="card mb-10">
                        <div class="table-responsive">
                           <table class="table table-rounded table-striped border gy-7 gs-7 mb-0">
                              <tbody>
                                 <tr>
                                    <th class="text-center" style={{width:"30%"}}>
                                       <div class="text-center">{isShowBoth?'Total Questions /':''}  {t('Total Questions')}</div>
                                    </th>
                                    <th class="text-center" style={{width:"35%"}}>
                                       <div class="text-center">{isShowBoth?'Minimum Correct Answers Required /':''} {t('Minimum Correct Answers Required')}</div>
                                    </th>
                                    <th class="text-center" style={{width:"35%"}}>
                                       <div class="text-center">{isShowBoth?'Correct Answers /':''} {t('Correct Answers')}</div>
                                    </th>
                                 </tr>
                                 <tr>
                                    <td>
                                       <div class="text-center">{totalQuestion}</div>
                                    </td>
                                    <td>
                                       <div class="text-center">{requireAns}</div>
                                    </td>
                                    <td>
                                       <div class="text-center">{correctAns}</div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
            <div id="kt_app_footer" class="app-footer no-print">
               <div class="app-container  container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack py-3 ">
                  <div class="text-dark order-2 order-md-1">
                     <span class="text-muted fw-semibold me-1">2023©</span>
                     <a href="#" target="_blank" class="text-gray-800 text-hover-primary">Sweden Driving Center</a>
                  </div>
                  <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                     {
                        currentLocale=='en'?(
                           <>
                           <li class="menu-item"><button  class="btn btn-dark me-4" onClick={() => convertToArabic(1)}><i class="bi bi-translate fs-4 me-2"></i> Arabic</button></li>,
                           <li class="menu-item"><a  class="btn btn-success me-4" href='https://docs.google.com/forms/d/e/1FAIpQLSfLpWA6rbaStOGQBOANupRaOe9vPP3JpNFx6VGaYcj8Erfsvg/viewform?pli=1' target='_blank' ><i class="bi bi-person-raised-hand fs-4 me-2"></i>Feedback </a></li>,
                           <li class="menu-item"><a  class="btn btn-warning me-4" href='https://docs.google.com/forms/d/e/1FAIpQLSfKG_SPJKc3H5dHqFnU51nMh_lE1wk_ZveubUd-AO6NCItSYg/viewform' target='_blank'><i class="bi bi-graph-up-arrow fs-4 me-2"></i>Survey</a></li>
                           </>
                        ):(
                           <>
                           <li class="menu-item"><button  class="btn btn-dark me-4" onClick={() => convertToEnglish(1)}><i class="bi bi-translate fs-4 me-2"></i>English</button></li>,
                           <li class="menu-item"><a  class="btn btn-success me-4" href='https://docs.google.com/forms/d/e/1FAIpQLScA6ADeY110OTYuY-dnCKcZquEe6eAhlDu5AYD9R4i7CUo1qQ/viewform' target='_blank' ><i class="bi bi-person-raised-hand fs-4 me-2"></i>Feedback </a></li>,
                           <li class="menu-item"><a  class="btn btn-warning me-4" href='https://docs.google.com/forms/d/e/1FAIpQLSdlT8b3ZhU4VE0mzMU0e08GzqiEQ2PRUMt20Bk-0ry5W-Wm-w/viewform' target='_blank'><i class="bi bi-graph-up-arrow fs-4 me-2"></i>Survey</a></li>
                           </>
                        )
                        
                     
                     }
{ !isShowBoth?
            <li class="menu-item"><button  class="btn btn-success me-4" onClick={handleBothState}><i class="bi bi-translate fs-4 me-2"></i>Both</button></li>
            :''
}
           
                 
                     {/* <li class="menu-item"><button  class="btn btn-primary me-4" onClick={printToPDF}><i class="bi bi-file-earmark-pdf fs-4 me-2"></i> PDF</button></li> */}
                     <li class="menu-item"><a href="#" class="btn btn-info me-4" onClick={handlePrintPreview}><i class="bi bi-printer fs-4 me-2"></i> Print </a></li>

                     <li className="menu-item no-print"><a href="/dashboard" class="btn btn-danger me-4"><i class="bi bi-x-lg fs-4 me-2"></i> Close</a></li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>




    </>
  )
}

export default StdResults
